import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import sostieni from "../images/sostieni.jpg"

const SostieniPage = () => (
  <div>
    <Header/>

    <div className="columns2 reverse white-bg">
      <div className="image-col">
        <img src={sostieni}/>
      </div>
      <div className="text-col">
        <h2>sostieni Silene</h2>
        <p>
          Silene Ideas for CARE è una Fondazione senza scopo di lucro che, sostenendo l'innovazione attraverso la
          realizzazione di nuovi modelli di assistenza e di cura integrati con attività e progetti
          medico-assistenziali, sviluppa progetti fornendo supporto formativo, tecnico e finanziario.<br/>
          La partecipazione allo sviluppo del progetto Silene è aperta a tutti, non solo sul piano finanziario,
          ma anche condividendo esperienze e conoscenze per dare nuovo impulso, in maniera consapevole e critica,
          al variegato universo del "sapere".
        </p>
      </div>
    </div>

    <div className="content-pad">
      <h2>per effettuare un bonifico</h2>
      <span className="bigger-text">
        FONDAZIONE SILENE IDEAS FOR CARE - ETS. IBAN: IT34H0200803284000106191901<br/>
        BANCA UNICREDIT
      </span>
    </div>

    <div className="content-pad white-bg grey-bg">
      <h2>agevolazioni fiscali</h2>
      <p>
        Queste le principali agevolazioni fiscali previste ad oggi dalla normativa in materia vigente. Ti consigliamo
        comunque di contattare il tuo consulente fiscale di fiducia per tutti gli approfondimenti necessari.
      </p>
      <ul className="bigger-text">
        <li>Detrazione e limite (Persona fisica): 30% su un massimo di Euro 30.000 di erogazione liberale</li>
        <li>Deduzione (Persona fisica): massimo 10% del reddito</li>
        <li>Deduzione (Società): massimo 10% del reddito</li>
      </ul>
      <p>
        Per le persone fisiche che effettuano erogazioni liberali a favore della Fondazione Silene Silene Ideas for Care
        (ente del Terzo settore) è prevista una detrazione Irpef pari al 30% degli oneri sostenuti, calcolata su un
        importo massimo di Euro 30.000, oppure una deduzione dell'importo erogato nel limite del 10% del reddito
        complessivo dichiarato.<br/>
        Per le liberalità in denaro o in natura erogate a favore della Fondazione Silene, da enti e società,
        è prevista la deduzione dal reddito complessivo netto del soggetto erogatore nel limite del 10% del
        reddito complessivo dichiarato.
      </p>
    </div>

    <Footer/>
  </div>
)

export default SostieniPage
